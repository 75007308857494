<template>
  <font-awesome-icon :icon="resolvedIcon" />
</template>
<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { defineProps, computed } from 'vue';
import type { MsIcon, MsIconV } from '../lib/services/types';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons/faCircleInfo';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons/faAngleDoubleRight';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons/faAngleDoubleLeft';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons/faPaperPlane';
import { faObjectGroup } from '@fortawesome/free-regular-svg-icons/faObjectGroup';
import { faAddressBook } from '@fortawesome/free-regular-svg-icons/faAddressBook';
import { faInbox } from '@fortawesome/free-solid-svg-icons/faInbox';
import { faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons/faEnvelopeOpen';
import { faMobilePhone } from '@fortawesome/free-solid-svg-icons/faMobilePhone';
import { faSms } from '@fortawesome/free-solid-svg-icons/faSms';
import { faPuzzlePiece } from '@fortawesome/free-solid-svg-icons/faPuzzlePiece';
import { faBolt } from '@fortawesome/free-solid-svg-icons/faBolt';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faRobot } from '@fortawesome/free-solid-svg-icons/faRobot';
import { faMobileRetro } from '@fortawesome/free-solid-svg-icons/faMobileRetro';
import { faCreditCard } from '@fortawesome/free-regular-svg-icons/faCreditCard';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faChartLine } from '@fortawesome/free-solid-svg-icons/faChartLine';
import { faMagnifyingGlassChart } from '@fortawesome/free-solid-svg-icons/faMagnifyingGlassChart';
import { faBell } from '@fortawesome/free-regular-svg-icons/faBell';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faEye } from '@fortawesome/free-regular-svg-icons/faEye';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faForward } from '@fortawesome/free-solid-svg-icons/faForward';
import { faReply } from '@fortawesome/free-solid-svg-icons/faReply';
import { faFolderMinus } from '@fortawesome/free-solid-svg-icons/faFolderMinus';
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen';
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faSave } from '@fortawesome/free-regular-svg-icons/faSave';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons/faCheckCircle';
import { faCopy } from '@fortawesome/free-regular-svg-icons/faCopy';
import { faShuffle } from '@fortawesome/free-solid-svg-icons/faShuffle';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons/faExchangeAlt';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons/faBoxOpen';
import { faParagraph } from '@fortawesome/free-solid-svg-icons/faParagraph';
import { faBold } from '@fortawesome/free-solid-svg-icons/faBold';
import { faItalic } from '@fortawesome/free-solid-svg-icons/faItalic';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { faAlignJustify } from '@fortawesome/free-solid-svg-icons/faAlignJustify';
import { faAlignCenter } from '@fortawesome/free-solid-svg-icons/faAlignCenter';
import { faAlignLeft } from '@fortawesome/free-solid-svg-icons/faAlignLeft';
import { faAlignRight } from '@fortawesome/free-solid-svg-icons/faAlignRight';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons/faStar';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons/faStar';
import { faCrown } from '@fortawesome/free-solid-svg-icons/faCrown';
import { faFileImport } from '@fortawesome/free-solid-svg-icons/faFileImport';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons/faTriangleExclamation';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons/faCircleExclamation';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons/faPaperclip';
import { faBookOpen } from '@fortawesome/free-solid-svg-icons/faBookOpen';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faBookDead } from '@fortawesome/free-solid-svg-icons/faBookDead';
import { faFileExport } from '@fortawesome/free-solid-svg-icons/faFileExport';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons/faQuestionCircle';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faTrashRestore } from '@fortawesome/free-solid-svg-icons/faTrashRestore';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons/faTimesCircle';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons/faEllipsisVertical';
import { faFlask } from '@fortawesome/free-solid-svg-icons/faFlask';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faIndustry } from '@fortawesome/free-solid-svg-icons/faIndustry';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faFingerprint } from '@fortawesome/free-solid-svg-icons/faFingerprint';
import { faPlug } from '@fortawesome/free-solid-svg-icons/faPlug';
import { faInfinity } from '@fortawesome/free-solid-svg-icons/faInfinity';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons/faFilePdf';
import { faFile } from '@fortawesome/free-regular-svg-icons/faFile';
import { faFileImage } from '@fortawesome/free-regular-svg-icons/faFileImage';
import { faSpellCheck } from '@fortawesome/free-solid-svg-icons/faSpellCheck';
import { faImage } from '@fortawesome/free-regular-svg-icons/faImage';
import { faClock } from '@fortawesome/free-regular-svg-icons/faClock';
import { faServer } from '@fortawesome/free-solid-svg-icons/faServer';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faMailBulk } from '@fortawesome/free-solid-svg-icons/faMailBulk';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faTheaterMasks } from '@fortawesome/free-solid-svg-icons/faTheaterMasks';
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan';
import { faUserSlash } from '@fortawesome/free-solid-svg-icons/faUserSlash';
import { faStamp } from '@fortawesome/free-solid-svg-icons/faStamp';
import { faAt } from '@fortawesome/free-solid-svg-icons/faAt';
import { faShippingFast } from '@fortawesome/free-solid-svg-icons/faShippingFast';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons/faUserFriends';
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter';
import { faFolderOpen } from '@fortawesome/free-regular-svg-icons/faFolderOpen';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons/faSyncAlt';
import { faMoon } from '@fortawesome/free-regular-svg-icons/faMoon';
import { faSun } from '@fortawesome/free-regular-svg-icons/faSun';
import { faPhotoFilm } from '@fortawesome/free-solid-svg-icons/faPhotoFilm';
import { faCirclePlay } from '@fortawesome/free-regular-svg-icons/faCirclePlay';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus';
import { faCommentsDollar } from '@fortawesome/free-solid-svg-icons/faCommentsDollar';
import { faReceipt } from '@fortawesome/free-solid-svg-icons/faReceipt';
import { faRepeat } from '@fortawesome/free-solid-svg-icons/faRepeat';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons/faLayerGroup';
import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons/faExpandArrowsAlt';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons/faMobileAlt';
import { faDesktop } from '@fortawesome/free-solid-svg-icons/faDesktop';
import { faTabletAlt } from '@fortawesome/free-solid-svg-icons/faTabletAlt';
import { faFileCode } from '@fortawesome/free-regular-svg-icons/faFileCode';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { faChartSimple } from '@fortawesome/free-solid-svg-icons/faChartSimple';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faCalendar } from '@fortawesome/free-regular-svg-icons/faCalendar';
import { faIdCard } from '@fortawesome/free-regular-svg-icons/faIdCard';
import { faIdCardAlt } from '@fortawesome/free-solid-svg-icons/faIdCardAlt';
import { faEnvelopeCircleCheck } from '@fortawesome/free-solid-svg-icons/faEnvelopeCircleCheck';
import type { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faWrench } from '@fortawesome/free-solid-svg-icons/faWrench';
import { faKey } from '@fortawesome/free-solid-svg-icons/faKey';
import { assertUnreachable } from '../lib/services/assertUnreachable';
import { faShapes } from '@fortawesome/free-solid-svg-icons/faShapes';

const props = defineProps<{
  icon: MsIcon;
}>();
function getIcon(msIcon: MsIconV): IconDefinition {
  switch (msIcon) {
    case 'plugins':
      return faShapes;
    case 'emailVerification':
      return faEnvelopeCircleCheck;
    case 'guests':
      return faIdCardAlt;
    case 'portals':
      return faIdCard;
    case 'calendar':
      return faCalendar;
    case 'customConnector':
      return faWrench;
    case 'videoPlay':
      return faCirclePlay;
    case 'thumbnail':
      return faPhotoFilm;
    case 'darkMode':
      return faMoon;
    case 'lightMode':
      return faSun;
    case 'tick':
      return faCheck;
    case 'cross':
    case 'close':
    case 'times':
      return faTimes;
    case 'redrive':
      return faSyncAlt;
    case 'delete':
      return faTrash;
    case 'forward':
      return faForward;
    case 'usageSeries':
      return faChartSimple;
    case 'spinner':
      return faSpinner;
    case 'reply':
      return faReply;
    case 'deleteAll':
      return faFolderMinus;
    case 'download':
      return faDownload;
    case 'refresh':
      return faSync;
    case 'edit':
      return faPen;
    case 'seen':
      return faEye;
    case 'cog':
      return faCog;
    case 'closeCircle':
      return faTimesCircle;
    case 'chevronRight':
      return faChevronRight;
    case 'organization':
      return faIndustry;
    case 'permanent':
      return faCheck;
    case 'menuBars':
      return faBars;
    case 'infinite':
      return faInfinity;
    case 'connectors':
    case 'connector':
      return faPlug;
    case 'saml':
      return faFingerprint;
    case 'linkExternal':
      return faExternalLinkAlt;
    case 'test':
      return faFlask;
    case 'filePdf':
      return faFilePdf;
    case 'file':
      return faFile;
    case 'fileImage':
      return faFileImage;
    case 'circleInfo':
      return faCircleInfo;
    case 'angleDoubleRight':
      return faAngleDoubleRight;
    case 'angleDoubleLeft':
      return faAngleDoubleLeft;
    case 'compose':
      return faPaperPlane;
    case 'templates':
      return faObjectGroup;
    case 'fullScreen':
      return faExpandArrowsAlt;
    case 'deviceMobile':
      return faMobileAlt;
    case 'deviceTablet':
      return faTabletAlt;
    case 'deviceDesktop':
      return faDesktop;
    case 'contacts':
      return faAddressBook;
    case 'inbox':
      return faInbox;
    case 'emails':
      return faEnvelopeOpen;
    case 'phones':
      return faMobilePhone;
    case 'sms':
      return faSms;
    case 'webhooks':
      return faPuzzlePiece;
    case 'automations':
      return faBolt;
    case 'validation':
      return faCheck;
    case 'ai':
      return faRobot;
    case 'devicePreview':
      return faMobileRetro;
    case 'upgrade':
      return faCreditCard;
    case 'team':
      return faUsers;
    case 'usage':
      return faChartLine;
    case 'reputation':
      return faMagnifyingGlassChart;
    case 'notifications':
      return faBell;
    case 'temporary':
      return faClock;
    case 'plus':
    case 'create':
      return faPlus;
    case 'save':
      return faSave;
    case 'warning':
      return faTriangleExclamation;
    case 'error':
      return faCircleExclamation;
    case 'tickCircle':
      return faCheckCircle;
    case 'view':
      return faFolderOpen;
    case 'filter':
      return faFilter;
    case 'invoices':
      return faReceipt;
    case 'createUser':
      return faUserPlus;
    case 'connectorSync':
      return faRepeat;
    case 'complaints':
      return faCircleExclamation;
    case 'infoCircle':
      return faInfoCircle;
    case 'copy':
      return faCopy;
    case 'chevronDown':
      return faChevronDown;
    case 'random':
      return faShuffle;
    case 'groups':
      return faLayerGroup;
    case 'missed':
      return faTrashRestore;
    case 'search':
      return faSearch;
    case 'questionCircle':
      return faQuestionCircle;
    case 'export':
      return faFileExport;
    case 'expired':
      return faBookDead;
    case 'arrowLeft':
      return faArrowLeft;
    case 'arrowRight':
      return faArrowRight;
    case 'addons':
      return faBoxOpen;
    case 'documentation':
      return faBookOpen;
    case 'attachment':
    case 'attachments':
      return faPaperclip;
    case 'paragraph':
      return faParagraph;
    case 'bold':
      return faBold;
    case 'italic':
      return faItalic;
    case 'link':
      return faLink;
    case 'alignLeft':
      return faAlignLeft;
    case 'alignCenter':
      return faAlignCenter;
    case 'alignJustify':
      return faAlignJustify;
    case 'alignRight':
      return faAlignRight;
    case 'favouriteDisabled':
      return faStarRegular;
    case 'favouriteEnabled':
      return faStarSolid;
    case 'fileUpload':
      return faFileImport;
    case 'events':
      return faChartLine;
    case 'inboundEmails':
      return faEnvelopeOpen;
    case 'tracking':
      return faEye;
    case 'organizationUsers':
      return faUserFriends;
    case 'quote':
      return faPuzzlePiece;
    case 'sent':
      return faShippingFast;
    case 'repliers':
      return faRobot;
    case 'domains':
      return faAt;
    case 'dropdown':
      return faEllipsisVertical;
    case 'deliveryStatus':
      return faStamp;
    case 'paidFeature':
      return faCrown;
    case 'inboxRuleset':
      return faExchangeAlt;
    case 'testWebhook':
      return faBolt;
    case 'userSeats':
      return faUserPlus;
    case 'userList':
      return faUserFriends;
    case 'phonePlans':
      return faCommentsDollar;
    case 'server':
      return faServer;
    case 'chevronUp':
      return faChevronUp;
    case 'createCircle':
      return faPlusCircle;
    case 'bounceRecipients':
      return faUserSlash;
    case 'apiKey':
      return faKey;
    case 'bounces':
      return faBan;
    case 'home':
      return faHome;
    case 'zapier':
      return faFileCode;
    case 'alias':
    case 'aliases':
      return faTheaterMasks;
    case 'users':
      return faUsers;
    case 'extraEmails':
      return faMailBulk;
    case 'extraInboxes':
      return faMailBulk;
    case 'clock':
      return faClock;
    case 'spellCheck':
      return faSpellCheck;
    case 'image':
      return faImage;
    default:
      return assertUnreachable(msIcon);
  }
}
// Compute the resolved icon based on the provided icon name
const resolvedIcon = computed(() => getIcon(props.icon));
</script>
